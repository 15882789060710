import React from 'react'

import {Header2, ExtraSmallBody} from 'src/components/text'
import {Box, Flex, Grid} from 'src/components/Box'
import {GreenYellowUnderline} from 'src/components/emphasis'
import RadioInput from 'src/components/RadioInput'
import {Action} from 'src/components/Action'
import {SearchTarget, SearchSort} from 'src/hooks/useSearch'

interface SearchOptionsProps {
  target: SearchTarget
  onTargetChange: (value: SearchTarget) => void
  sort: SearchSort
  onSortChange: (value: SearchSort) => void
  showSortOptions?: boolean
}

const SearchOptions = ({
  target,
  onTargetChange,
  sort,
  onSortChange,
  showSortOptions = true,
}: SearchOptionsProps) => (
  <>
    <Flex
      flexDirection={{default: 'column', md: 'row'}}
      justifyContent="space-between"
    >
      <Grid gridAutoFlow="column" gridGap="3">
        <SearchOptionHeader
          selected={target === SearchTarget.Algolia}
          onSelect={() => onTargetChange(SearchTarget.Algolia)}
        >
          GetSchooled.com
        </SearchOptionHeader>
        <Box backgroundColor="grey2" width="3px" height="45px" />
        <SearchOptionHeader
          selected={target === SearchTarget.YouTube}
          onSelect={() => onTargetChange(SearchTarget.YouTube)}
        >
          Videos Only
        </SearchOptionHeader>
      </Grid>

      {showSortOptions ? (
        <Box mt={{default: 4, md: 2}} maxWidth="350px">
          <Grid
            gridAutoFlow="column"
            gridGap="1"
            alignItems="center"
            as="fieldset"
          >
            <ExtraSmallBody>Sort by:</ExtraSmallBody>
            <RadioInput
              name="sort"
              label="Relevance"
              value="relevance"
              checked={sort === SearchSort.Relevance}
              onChange={() => onSortChange(SearchSort.Relevance)}
            />
            <RadioInput
              name="sort"
              label="Recently Updated"
              value="date"
              checked={sort === SearchSort.Date}
              onChange={() => onSortChange(SearchSort.Date)}
            />
          </Grid>
        </Box>
      ) : null}
    </Flex>
  </>
)

type SearchOptionHeaderProps = {
  selected: boolean
  onSelect?: () => void
  children?: React.ReactNode
}

const SearchOptionHeader = ({
  selected,
  onSelect,
  children,
}: SearchOptionHeaderProps) => (
  <>
    {selected ? (
      <Header2>
        <GreenYellowUnderline>{children}</GreenYellowUnderline>
      </Header2>
    ) : (
      <Action onClick={onSelect}>
        <Header2 color="grey5" truncate>
          {children}
        </Header2>
      </Action>
    )}
  </>
)

export default SearchOptions
