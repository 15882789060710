import React from 'react'

import {HeaderXl, Body, SmallBody} from 'src/components/text'
import {externalPaths, localPaths} from 'src/urls'

const ContentDatabasePreamble: React.FunctionComponent = () => (
  <>
    <HeaderXl>Content Library</HeaderXl>
    <Body>
      Welcome, Get Schooled Educators and Partners! We're excited to offer you
      exclusive access to our comprehensive library of resources, designed to
      engage and inspire your students. Browse hundreds of research-based,
      student-friendly articles, videos, and materials tailored to their needs.
      Simply type any keyword in the search bar below to unlock direct links to
      our website and{' '}
      <a href={externalPaths.youtube} target="_blank" rel="noopener noreferrer">
        YouTube content
      </a>{' '}
      —perfect for sharing with students or integrating into your school's
      website and Google Classroom. Be sure to also visit our{' '}
      <a
        href={localPaths.resourceHub}
        target="_blank"
        rel="noopener noreferrer"
      >
        Resource Hub for College & Career Professionals
      </a>{' '}
      for all of our educator-facing resources. Empower your students with our
      trusted resources today!
    </Body>
    <Body>
      <b>
        Need help?{' '}
        <a href="mailto:hello@getschooled.com">hello@getschooled.com</a>
      </b>
    </Body>
    <SmallBody mb="4">
      By using our Videos Only search, you agree to comply with the{' '}
      <a
        href={externalPaths.youtubeTerms}
        target="_blank"
        rel="noopener noreferrer"
      >
        YouTube Terms of Service
      </a>
      .
    </SmallBody>
  </>
)
export default ContentDatabasePreamble
