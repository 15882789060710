import {InputHTMLAttributes} from 'react'

import * as styles from 'src/styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'radio'
  name: string
  value: string
  label: string
}

const RadioInput = (props: Props) => {
  const id = props.id || [props.value, props.name].join('-')
  return (
    <div
      css={(theme) => ({
        position: 'relative',
        display: 'inline-flex',
        'input + label': {
          borderColor: theme.colors.grey4,
        },
        'input:checked + label': {
          borderColor: theme.colors.green,
        },
      })}
    >
      <input
        type="radio"
        {...props}
        id={id}
        css={{
          position: 'absolute',
          opacity: 0,
          width: 0,
          height: 0,
        }}
      />
      <label
        htmlFor={id}
        css={(theme) => ({
          cursor: 'pointer',
          border: '3px solid',
          borderRadius: '100px',
          boxSizing: 'border-box',
          padding: `2px 12px`,
          transition: styles.animation.selection('border-color'),
          color: theme.colors.grey6,
          ...styles.typography.smallBody,
        })}
      >
        {props.label}
      </label>
    </div>
  )
}

export default RadioInput
