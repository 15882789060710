import {useState, useCallback, useRef, useEffect} from 'react'

type UseTimeout = (
  callback: () => void,
  timeoutDelayMs?: number
) => {
  start: () => void
  clear: () => void
}

const useTimeout: UseTimeout = (callback, timeoutDelayMs = 0) => {
  const [isTimeoutActive, setIsTimeoutActive] = useState(false)
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const start = useCallback(() => {
    setIsTimeoutActive(true)
  }, [setIsTimeoutActive])

  const clear = useCallback(() => {
    setIsTimeoutActive(false)
  }, [setIsTimeoutActive])

  const execute = useCallback(() => {
    callbackRef.current && callbackRef.current()
    clear()
  }, [callbackRef, clear])

  useEffect(() => {
    if (isTimeoutActive) {
      const timeout = window.setTimeout(execute, timeoutDelayMs)

      return () => window.clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeoutActive])

  return {
    start,
    clear,
  }
}

export default useTimeout
