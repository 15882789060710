import {useEffect} from 'react'

import {SearchParameters} from 'src/hooks/useSearch'

const useDefaultSearchOnMount = (
  defaultParams: SearchParameters,
  search: (params: SearchParameters) => void
) =>
  useEffect(() => {
    if (defaultParams.query) {
      search(defaultParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

export default useDefaultSearchOnMount
