import React, {useState, useCallback, useMemo} from 'react'
import formatDistance from 'date-fns/formatDistance'
import styled from '@emotion/styled'

import {Header4, SmallBody} from 'src/components/text'
import {Box, Flex} from 'src/components/Box'
import Card from 'src/components/Card'
import * as styles from 'src/styles'
import {Action} from 'src/components/Action'
import {CardsIcon} from 'src/components/icons'
import {SearchResult} from 'src/hooks/useSearch'
import useTimeout from 'src/hooks/useTimeout'

const labelCopy = 'Copy'
const labelCopied = 'Copied!'
const timeoutCopied = 1500

const SearchResultCard = ({
  id,
  publishedAt,
  updatedAt,
  heroUrl,
  title,
  summary,
  link,
}: SearchResult) => {
  const [copyLabel, setCopyLabel] = useState(labelCopy)

  const linkInputId = `link_${id}`
  const onCopy = useOnCopy(linkInputId, setCopyLabel)

  const dates = useMemo(() => {
    const published = publishedAt
      ? new Date(publishedAt).toLocaleString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : null

    const updated = updatedAt
      ? formatDistance(new Date(updatedAt), Date.now())
      : null

    if (!published && updated) {
      return `Updated ${updated} ago`
    } else if (published) {
      return `Published ${published} ${
        updated ? `- Updated ${updated} ago` : ''
      }`
    }

    return ''
  }, [publishedAt, updatedAt])

  const absoluteUrl =
    link[0] === '/' ? process.env.GATSBY_CANONICAL_BASE_URL + link : link

  return (
    <Box minWidth="0">
      <Card>
        <Flex flexDirection="row" flexGrow={1}>
          {heroUrl ? (
            <SearchResultAnchor href={link} target="_blank" aria-hidden>
              <HeroImage
                backgroundImage={`url(${heroUrl})`}
                width={{_: '150px', md: '200px', lg: '300px'}}
              />
            </SearchResultAnchor>
          ) : null}

          <Flex minWidth="0" overflow="hidden" auto flexDirection="column">
            <SearchResultAnchor href={link} target="_blank">
              <SmallBody color="grey5" truncate>
                {dates}
              </SmallBody>
              <Header4 truncate mt="1">
                {title}
              </Header4>
              <SmallBody truncate mt="1">
                {summary}
              </SmallBody>
            </SearchResultAnchor>

            <Flex mt="2" alignItems="center">
              <LinkInput
                value={absoluteUrl}
                id={linkInputId}
                type="url"
                readOnly
                onFocus={selectOnFocus}
                aria-label="Web address"
              />
              <LinkAction onClick={onCopy} aria-label="Copy to clipboard">
                <Flex alignItems="center">
                  <CardsIcon color="grey6" mr="1" />
                  <SmallBody color="grey6">{copyLabel}</SmallBody>
                </Flex>
              </LinkAction>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Box>
  )
}

const useOnCopy = (linkInputId: string, setCopyLabel: (v: string) => void) => {
  const resetCopyLabel = useCallback(
    () => setCopyLabel(labelCopy),
    [setCopyLabel]
  )

  const {start: resetCopyLabelAfterDelay} = useTimeout(
    resetCopyLabel,
    timeoutCopied
  )

  return useCallback(() => {
    const inputElement = document.getElementById(
      linkInputId
    ) as HTMLInputElement

    if (inputElement != null) {
      inputElement.focus()
      inputElement.select()

      document.execCommand('copy')

      inputElement.setSelectionRange(0, 0)
      inputElement.blur()

      setCopyLabel(labelCopied)
      resetCopyLabelAfterDelay()
    }
  }, [linkInputId, setCopyLabel, resetCopyLabelAfterDelay])
}

const selectOnFocus = (event: React.FocusEvent<HTMLInputElement>) =>
  event.target.select()

const SearchResultAnchor = styled.a({textDecoration: 'none'})

const LinkInput = styled.input(({theme}) => ({
  color: theme.colors.grey5,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  backgroundColor: theme.colors.grey1,
  flex: 1,
  padding: theme.space[1],
  ...styles.borders.simpleBorder(1),
  ...styles.typography.smallBody,
}))

const LinkAction = styled(Action)(({theme}) => ({
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  backgroundColor: theme.colors.grey2,
  minWidth: 'initial',
  padding: `${theme.space[1]} ${theme.space[2]}`,
  ...styles.borders.simpleBorder(1),
  marginLeft: '-2px',
}))

const HeroImage = styled(Box)({
  marginTop: '-24px',
  marginLeft: '-24px',
  marginBottom: '0',
  marginRight: '16px',
  height: 'calc(100% + 24px * 2)',
  borderTopLeftRadius: 19,
  borderBottomLeftRadius: 19,
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
})

export default SearchResultCard
