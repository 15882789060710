import React from 'react'

import {Header3, SmallBody} from 'src/components/text'

const NoSearchResults = () => (
  <>
    <Header3>No Search Results</Header3>
    <SmallBody>
      We're sorry that we don't seem to have any content matching your search
      terms. You can try again or send us a note to{' '}
      <a href="mailto:hello@getschooled.com">hello@getschooled.com</a> with what
      you're looking for and we'll add it to our 'build' list!
    </SmallBody>
  </>
)

export default NoSearchResults
