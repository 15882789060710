import React, {useState, useCallback, useEffect} from 'react'

import {Grid} from 'src/components/Box'
import SearchForm from 'src/components/SearchForm'
import {Divider} from 'src/components/dividers'
import ContentDatabasePreamble from 'src/components/ContentDatabase/ContentDatabasePreamble'
import SearchOptions from 'src/components/ContentDatabase/SearchOptions'
import ContentDatabaseSearchContent from 'src/components/ContentDatabase/ContentDatabaseSearchContent'
import useSearch, {SearchTarget, SearchSort} from 'src/hooks/useSearch'
import useDefaultSearchOnMount from 'src/hooks/useDefaultSearchOnMount'
import useSearchOnTargetChange from 'src/hooks/useSearchOnTargetChange'
import useSearchOnSortChange from 'src/hooks/useSearchOnSortChange'

const resultsPerPage = 20
// const articlesAndResourcePagesOnly = [['tile', 'resource_page']]

interface ContentDatabaseProps {
  defaultQuery?: string
  defaultTarget?: SearchTarget
  defaultSort?: SearchSort
}

const ContentDatabase = ({
  defaultQuery = '',
  defaultTarget = SearchTarget.Algolia,
  defaultSort = SearchSort.Relevance,
}: ContentDatabaseProps) => {
  const {status, existingSearch, searchResults, hasMore, loadMore, search} =
    useSearch({
      resultsPerPage,
      // algoliaTagFilters: articlesAndResourcePagesOnly,
    })

  const [query, setQuery] = useState(defaultQuery)
  const [target, setTarget] = useState(defaultTarget)
  const [sort, setSort] = useState(defaultSort)
  const [placeholder, setPlaceholder] = useState('Search Articles')

  useEffect(() => {
    if (target === SearchTarget.Algolia) {
      setPlaceholder('Search Articles')
    } else if (target === SearchTarget.YouTube) {
      setPlaceholder('Search Videos')
    }
  }, [target])

  const performSearch = useCallback(() => {
    search({query, target, sort})
  }, [search, query, target, sort])

  useDefaultSearchOnMount(
    {query: defaultQuery, target: defaultTarget, sort: defaultSort},
    search
  )

  useSearchOnTargetChange(target, status, existingSearch, search)
  useSearchOnSortChange(sort, status, existingSearch, search)

  const formDisabled =
    status === 'pending' ||
    !query ||
    (status === 'resolved' &&
      query === existingSearch?.query &&
      target === existingSearch?.target &&
      sort === existingSearch?.sort)

  return (
    <>
      <Grid gridGap="4" mb="4">
        <ContentDatabasePreamble />

        <SearchForm
          query={query}
          setQuery={setQuery}
          performSearch={performSearch}
          disabled={formDisabled}
          autofocus
          placeholder={placeholder}
          ariaLabel="Search content"
        />

        <SearchOptions
          target={target}
          onTargetChange={setTarget}
          sort={sort}
          onSortChange={setSort}
        />

        <Divider style={{height: '3px'}} />
      </Grid>

      <Grid gridGap="2">
        <ContentDatabaseSearchContent
          status={status}
          searchResults={searchResults ?? []}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </Grid>
    </>
  )
}

export default ContentDatabase
