import React from 'react'

import Button from 'src/components/Button'
import Loading from 'src/components/Loading'
import SearchResultCard from 'src/components/ContentDatabase/SearchResultCard'
import NoSearchTerm from 'src/components/ContentDatabase/NoSearchTerm'
import SearchError from 'src/components/ContentDatabase/SearchError'
import NoSearchResults from 'src/components/ContentDatabase/NoSearchResults'
import {SearchResult} from 'src/hooks/useSearch'

interface ContentDatabaseSearchContentProps {
  status: string
  searchResults: SearchResult[]
  hasMore: boolean
  loadMore: () => void
}

const ContentDatabaseSearchContent = ({
  status,
  searchResults,
  loadMore,
  hasMore,
}: ContentDatabaseSearchContentProps) => (
  <>
    {searchResults.map((result) => (
      <SearchResultCard key={result.id} {...result} />
    ))}

    {status === 'resolved' ? (
      <>
        {searchResults.length === 0 ? <NoSearchResults /> : null}

        {hasMore ? <Button onClick={loadMore}>Load more</Button> : null}
      </>
    ) : null}

    {status === 'idle' ? <NoSearchTerm /> : null}

    {status === 'rejected' ? <SearchError /> : null}

    {status === 'pending' ? <Loading size="medium" /> : null}
  </>
)

export default ContentDatabaseSearchContent
