import React from 'react'

import {Header3, SmallBody} from 'src/components/text'

const NoSearchTerm: React.FunctionComponent = () => (
  <>
    <Header3>Error</Header3>
    <SmallBody>
      There was an error loading search results. Please try again later.
    </SmallBody>
  </>
)

export default NoSearchTerm
