import {useEffect} from 'react'

import {SearchTarget, SearchParameters} from './useSearch'

const useSearchOnTargetChange = (
  target: SearchTarget,
  status: string,
  existingSearch: SearchParameters | null,
  search: (params: SearchParameters) => void
) => {
  const existingQuery = existingSearch?.query
  const existingTarget = existingSearch?.target
  const existingSort = existingSearch?.sort

  useEffect(() => {
    if (
      status !== 'resolved' ||
      existingQuery == null ||
      existingSort == null ||
      target === existingTarget
    ) {
      return
    }

    search({query: existingQuery, sort: existingSort, target})
  }, [existingQuery, existingSort, existingTarget, search, status, target])
}

export default useSearchOnTargetChange
