import {useEffect} from 'react'

import {SearchSort, SearchParameters} from './useSearch'

const useSearchOnSortChange = (
  sort: SearchSort,
  status: string,
  existingSearch: SearchParameters | null,
  search: (params: SearchParameters) => void
) => {
  const existingQuery = existingSearch?.query
  const existingTarget = existingSearch?.target
  const existingSort = existingSearch?.sort

  useEffect(() => {
    if (
      status !== 'resolved' ||
      existingQuery == null ||
      existingTarget == null ||
      sort === existingSort
    ) {
      return
    }

    search({query: existingQuery, target: existingTarget, sort})
  }, [existingQuery, existingTarget, existingSort, search, status, sort])
}

export default useSearchOnSortChange
