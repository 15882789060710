import React from 'react'
import {PageRendererProps} from 'gatsby'
import queryString from 'query-string'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import {Box, PositionContainer} from 'src/components/Box'
import FlairBox from 'src/components/FlairBox'
import ContentDatabase from 'src/components/ContentDatabase/ContentDatabase'
import {FlairProvider} from 'src/context/FlairContext'
import {SearchTarget} from 'src/hooks/useSearch'

const PartnerContentDatabase = ({location}: PageRendererProps) => {
  const {q, t} = queryString.parse(location.search)

  const defaultQuery = typeof q === 'string' ? q : ''

  const defaultTarget =
    typeof t === 'string'
      ? t === 'yt'
        ? SearchTarget.YouTube
        : SearchTarget.Algolia
      : undefined

  return (
    <ContentDatabaseLayout>
      <ContentDatabase
        defaultQuery={defaultQuery}
        defaultTarget={defaultTarget}
      />
    </ContentDatabaseLayout>
  )
}

const ContentDatabaseLayout = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[]
}) => {
  return (
    <FlairProvider seed={1}>
      <Layout>
        <SEO title="Educator Content Library" />
        <Box justifyContent="center" my="4">
          <PositionContainer position="relative">
            <FlairBox right={-100} height={200} width={200} />
            <FlairBox left={-150} top={400} height={100} width={100} />
          </PositionContainer>
          <Box maxWidth="920px">{children}</Box>
        </Box>
      </Layout>
    </FlairProvider>
  )
}

export default PartnerContentDatabase
