import React from 'react'

import {Header3, SmallBody} from 'src/components/text'

const NoSearchTerm: React.FunctionComponent = () => (
  <>
    <Header3>Enter a Search Term</Header3>
    <SmallBody>Begin a search by entering a search term above.</SmallBody>
  </>
)

export default NoSearchTerm
